

import { Vue, Component } from "vue-property-decorator";

import { EmployeeService } from "@/services/employee";
import { EmployeeDTO } from "@/services/employee/employee.dto";

interface TableHeader {
  text: string;
  value: string;
  [key: string]: string | boolean;
}

const ALL_EMPLOYEES = "All Employees";
const BLOCKED_EMPLOYEES = "Blocked Employees";
const UNBLOCKED_EMPLOYEES = "Unblocked Employees";

@Component
export default class Employees extends Vue {
  loading = false;
  searchTerm = "";
  employees: EmployeeDTO[] = [];
  filteredEmployees: EmployeeDTO[] = [];
  blockStatusItems = [ALL_EMPLOYEES, UNBLOCKED_EMPLOYEES, BLOCKED_EMPLOYEES];
  blockStatus = ALL_EMPLOYEES;
  headers: TableHeader[] = [
    {
      text: "",
      value: "",
      sortable: false
    },
    {
      text: "Employee Name",
      value: "name"
    },
    {
      text: "Contact Number",
      value: "contactNumber"
    },
    {
      text: "",
      value: "action"
    }
  ];

  created() {
    this.$store.commit("liveData/setSelectedActivity", 2);
    this.$store.commit("liveData/setSelectedActivityName", "Employees");
    this.getEmployees();
  }

  async getEmployees() {
    this.loading = true;
    try {
      this.employees = await EmployeeService.getEmployees(false);
    } catch (error) {
      console.log(error);
    }
    this.filterEmployees();
    this.loading = false;
  }

  filterEmployees() {
    if (this.blockStatus === ALL_EMPLOYEES) {
      this.filteredEmployees = [...this.employees];
    } else if (this.blockStatus === BLOCKED_EMPLOYEES) {
      this.filteredEmployees = this.employees.filter(
        employee => employee.dateOfLeaving
      );
    } else if (this.blockStatus === UNBLOCKED_EMPLOYEES) {
      this.filteredEmployees = this.employees.filter(
        employee => !employee.dateOfLeaving
      );
    }
  }

  async blockUnblockEmployee(employee: EmployeeDTO) {
    this.loading = true;
    const block: boolean = employee.dateOfLeaving ? false : true;
    try {
      await EmployeeService.updateEmployee(employee.employeeId, { block });
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
    this.getEmployees();
  }

  goToEmployeeDetails(employee: EmployeeDTO) {
    console.log(employee);
    this.$router.push("/employee/" + employee.employeeId);
  }

  goToAddEmployee() {
    this.$router.push("/employees/add");
  }
}
